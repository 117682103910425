/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  body {
    margin: 0;
    /* font-family:"Inter var", "sans-serif" !important; */
    background-color: var(--primary-50) !important; 
  }

  /* :root {
    --toastify-color-success: #3366FF !important;
    
    --toastify-color-light: #2a93f0d0 !important;
    --toastify-text-color-light: #002D72 !important;

  --toastify-color-progress-success: linear-gradient(
    to right,
    #6A00FF,
    #3366FF
  ) !important;

    --toastify-spinner-color: linear-gradient(
      to left,
      #6A00FF,
      #3366FF
    ) !important;

    --toastify-color-progress-dark: #bb86fc;
  
  } */
      /* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  } */
}

@layer components{
  
input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}

.button-base {
  @apply inline-block rounded md:min-w-32 md:max-w-60 px-4 py-3 md:py-2 text-base md:text-[0.8125rem] font-semibold uppercase tracking-widest transition duration-150 ease-in-out
}
.btn-primary{
  @apply button-base;
  @apply bg-[#3366CC] text-white shadow-[0_4px_9px_-4px_#3b71ca] disabled:bg-opacity-60 hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]
}

.btn-secondary {
  @apply button-base;
  @apply bg-transparent min-h-10 text-[#008080] disabled:text-primary-500 border-primary-600 border-2 disabled:hover:bg-transparent hover:bg-primary-50 focus:bg-transparent focus:outline-none focus:ring-0 active:bg-primary-accent-200
}

.btn-tertiary {
  @apply button-base;
  @apply bg-transparent text-primary-600 disabled:text-gray-500 border-0 hover:text-primary-500 focus:text-primary-500 focus:outline-none focus:ring-0 active:text-primary-700
}

.btn-cuatriary {
  @apply button-base;
  @apply bg-primary-600 text-white shadow-[0_4px_9px_-4px_#3b71ca] disabled:bg-opacity-60 hover:bg-primary-800 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-800 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]
}

.btn-quintuary {
  @apply button-base;
  @apply bg-transparent text-[#008080] disabled:text-gray-500 border-0 hover:text-primary-500 focus:text-primary-500 focus:outline-none focus:ring-0 active:text-primary-700
}

.loading-dialog-container {
  @apply fixed z-50;
  @apply self-center;
  @apply top-1/2 left-1/2;
  @apply -mt-[3.125rem] -ml-[3.125rem];
}

.select-opt input{
  @apply h-10
}
/*loadder*/
.loader-container {
  z-index: 9999;
  background-color: rgba(56, 55, 55, 0.5);

  @apply flex flex-col fixed top-0 left-0 w-full h-full items-center justify-center
}
.bg-primary-gradient{
  @apply bg-gradient-to-r from-[#6A00FF] to-[#3366FF]
}
.bg-sedondary-gradient{
  @apply bg-gradient-to-l from-[#6A00FF] to-[#6308e3c2]
}
/*sidevav*/
#sideNavMenu {
  /* width: 33.333333% !important;
  min-width: 30vw !important; */
  @apply lg:!w-1/5 md:!w-1/3 min-w-[90vw] md:!min-w-[25vw]
}

.sideNave-title {
  @apply text-2xl font-bold tracking-[0.75rem] hover:no-underline
}
/*end sidenav*/

/*header*/
.header-title{
  @apply font-normal text-xl text-slate-50
}
.header-link{
  @apply block transition duration-150 ease-in-out text-slate-50 font-bold text-sm md:text-base hover:text-suva-azul-fuerte focus:text-blue-900 disabled:text-black/30 dark:hover:text-white dark:focus:text-white  [&.active]:text-black/90 cursor-pointer
}
/*end header*/
#content.div-buton-show-hide-nav {
  @apply !p-5 text-center flex relative top-[-55px] h-max
}

/*grids*/
.grid-3{
  @apply grid grid-cols-1 md:grid-cols-3 col-start-2 items-start gap-2 w-full
}
.grid-4{
  @apply grid grid-cols-1 md:grid-cols-4 col-start-2 items-start gap-2 w-full
}

/*end grid*/
/*spinner*/
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}
/*end spinner*/
}