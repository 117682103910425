/*SELECT FILE*/
input[data-te-select-input-ref], div[data-te-select-form-outline-ref]{
    @apply h-14 md:h-12 text-base md:text-sm text-gray-900 bg-white disabled:bg-suva-cyan-100 disabled:text-gray-600 rounded-lg
}

label[data-te-select-label-ref]{
    @apply pointer-events-none absolute left-0 flex h-full select-none text-base md:text-sm tracking-wide text-suva-gris-oscuro font-normal leading-tight transition-all  before:pointer-events-none before:mt-[6.5px] before:mr-1 before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:transition-all peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:before:border-transparent peer-focus:text-lg peer-focus:leading-tight peer-focus:text-blue-600 peer-focus:before:border-suva-azul-50
}
/* para eliminar border del select */
div[data-te-input-notch-middle-ref]{
    @apply border-r-0 border-l-0;
    border-right-style: none;
    border-left-style: none;
}
div[data-te-input-notch-leading-ref]{
    @apply border-r-0;
    border-right-style: none;
}

