select {
    appearance: none;
    -MozAppearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' width='1.25rem' height='1.25rem'><path stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5'></path></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
    @apply text-sm block min-h-[auto] w-full rounded border-[1px] bg-transparent outline-none transition-all duration-200 ease-linear focus:border-2 focus:border-suva-azul-200 focus:outline-0 focus:bg-[#ddf2f7] data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-gray-200 dark:placeholder:text-gray-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 cursor-pointer data-[te-input-disabled]:bg-[#e9ecef] data-[te-input-disabled]:cursor-default group-data-[te-was-validated]/validation:mb-4 dark:data-[te-input-disabled]:bg-zinc-600 py-[0.32rem] m-0 px-3 leading-[1.6]
}

table {
    @apply table-fixed text-left text-sm font-light w-full leading-[1.6] bg-white border border-suva-gris-bajo
}
/* table > thead {
    @apply border-b font-normal px-[1.4rem] h-12 border border-suva-gris-bajo dark:border-neutral-500 sticky top-0 z-30 bg-suva-azul-50 dark:bg-neutral-800
} */
table > thead > tr > th {
    @apply py-2 px-1 font-semibold capitalize text-base text-clip overflow-hidden text-suva-gris-700 border-0 border-r border-suva-gris-bajo dark:text-white dark:border-neutral-500
}

table > thead > tr > th > div {
    @apply justify-center
}
tbody.divRows{
    @apply sticky z-10 bg-inherit
}
tbody.divRows > tr{
    @apply border-b border-neutral-200 dark:border-neutral-500 transition ease-in-out duration-300 motion-reduce:transition-none hover:bg-suva-azul-bajo dark:hover:bg-neutral-700 cursor-pointer
}

tbody.divRows > tr > td{
    @apply h-11 md:h-12 text-base text-suva-gris-medio whitespace-nowrap text-clip overflow-hidden px-1 py-0 border border-suva-gris-bajo dark:border-neutral-500 focus:outline-none hover:bg-suva-azul-bajo
}

tbody.divRows > tr > td > span {
    @apply pl-2
}

tbody.divRows > tr > td > div > input {
    @apply h-9 text-center text-base bg-info-100 border border-x-2 border-suva-cyan-100 border-t-transparent focus:border-2 focus:border-suva-azul-200 focus:outline-0 focus:bg-[#ddf2f7] hover:bg-suva-cyan-50 w-full
}

tbody.divRows > tr > td > div > input:disabled {
    @apply bg-gray-400 border-gray-500 !important;
}

tbody.divRows > tr > td > div > input[readonly] {
    background-color: #3b71ca1c !important;
}

tbody.divRows > tr > td > div {
    @apply mb-[0.125rem] min-h-[1.5rem] pl-0 ml-0 flex items-center
}

/*boton select all encabezados*/
thead > tr > th > div > div >input[type="checkbox"]{
    @apply  h-5 w-5 md:h-6 md:w-6 appearance-none bg-white  hover:bg-suva-azul-suave
            rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-300 outline-none 
            before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-['']
            checked:border-primary checked:bg-suva-azul-alto checked:before:opacity-[0.16] checked:after:absolute 
            checked:after:mt-[1.5px] checked:after:ml-[0.4rem] checked:after:block checked:after:h-[0.8125rem] 
            checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 
            checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent 
            checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] 
            hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
            focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] 
            focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
            focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
            focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 
            checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
            checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] 
            checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none 
            checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 
            checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent
            dark:border-neutral-600 dark:checked:border-primary dark:checked:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
            dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]
}

tbody.divRows > tr > td > div > div > input[type="checkbox"]{
    @apply relative float-none top-2 left-3 -ml-2 mt-[0.15rem] h-5 w-5 md:h-6 md:w-6 appearance-none 
            rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-300 outline-none 
            before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-['']
            checked:border-primary checked:bg-suva-azul-alto checked:before:opacity-[0.16] checked:after:absolute 
            checked:after:mt-[1.5px] checked:after:ml-[0.4rem] checked:after:block checked:after:h-[0.8125rem] 
            checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 
            checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent 
            checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] 
            hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
            focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] 
            focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
            focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
            focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 
            checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
            checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] 
            checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none 
            checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 
            checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent
            dark:border-neutral-600 dark:checked:border-primary dark:checked:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
            dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]
}

.button-pagination {
    @apply text-suva-azul-corporativo disabled:text-[#8da9eb] inline-block rounded p-1.5 transition duration-150 ease-in-out hover:bg-suva-azul-50 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 disabled:hover:bg-transparent dark:hover:bg-neutral-500 dark:disabled:hover:bg-transparent dark:disabled:text-neutral-600
}

.button-pagination > svg{
    @apply h-5 w-5
}




/* Adicionales por IA, table.css */
.table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .header-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Asegura anchos fijos */
  }

  .header-table td{
    border: 1px solid #ddd;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .header-table,
  .body-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Asegura anchos fijos */
  }
  
  .header-table th,
  .body-table td {
    border: 1px solid #ddd;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .header-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .body-virtualizer {
    @apply overflow-auto h-auto max-h-[75vh]
  }
  
  
  .sort-icon {
    margin-left: 5px;
  }
  
  /* Opcional: estilos para botones de paginación */
  .button-pagination {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin: 0 2px;
  }
  
  .button-pagination:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .sort-icon {
    @apply w-[15px] h-[10px] origin-bottom font-black mr-1 text-neutral-500 group-hover:opacity-100 transition hover:ease-in-out transform ease-linear duration-300 motion-reduce:transition-none dark:text-neutral-400 opacity-100;
  }
