/* cellInputValid.css */
.cell-valid-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .cell-valid-inputText{
    @apply h-9 text-center text-base bg-info-100 border border-x-2 border-suva-cyan-100 border-t-transparent focus:border-2 focus:border-suva-azul-200 focus:outline-0 focus:bg-[#ddf2f7] hover:bg-suva-cyan-50 w-full
  }
  
  .error-input {
    border: 1px solid rgba(255, 0, 0, 0.85);
    background-color: rgba(253, 198, 198, 0.956);
  }
  
  .error-icon-wrapper {
    position: absolute;
    top: 5px; /* Ajusta según sea necesario */
    right: 5px; /* Ajusta según sea necesario */
    pointer-events: none; /* Evita que el contenedor interfiera con los eventos del icono */
    display: flex;
    align-items: center;
  }
  
  .error-icon {
    pointer-events: all; /* Permite interactuar con el icono */
    width: 16px; /* Ajusta el tamaño si es necesario */
    height: 16px;
    cursor: pointer;
  }
  
  .error-tooltip {
    position: absolute;
    color: white;
    padding: 8px;
    border-radius: 4px;
    white-space: normal;
    z-index: 1000; /* Asegura que el tooltip esté encima de otros elementos */
    width: 200px;
    height: auto;
    line-height: normal;
    /* Efecto de sombra */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    @apply text-sm bg-red-600;
  }
  
  .error-tooltip::before {
    content: '';
    position: absolute;
    top: 100%; /* Posiciona la flecha en la parte inferior del tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: rgba(255, 0, 0, 0.85) transparent transparent transparent; /* Flecha apuntando hacia abajo */
  }
  /* end cellInputValid.css */